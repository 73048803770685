@import "ember-bootstrap/bootstrap";
@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);
@import "icons/devicons.min";
@import "icons/technology-icons";

h1, h2, h3 {
  font-family: 'Press Start 2P', cursive;
}

.devicons, .techicons {
  font-size: 500%;
}
